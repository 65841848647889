import React from "react"
import styled from '@emotion/styled'
import { css } from "@emotion/react"
import Img from "gatsby-image"
import fetchWithTimeout from '../utils/fetchWithTimeout'
import Layout from "../components/layout"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SEARCH_QUERY_URI = process.env.BACKEND_SERVER_URI + "/wikipediagame"

class WikipediaSolutionPath extends React.Component {
  state = {
    pathArticles: [],
    httpUris: []
  }

  componentDidMount() {
    let pathArticles = []
    let httpUris = []
    let articleUris = this.props.path.split('|')
    articleUris.forEach(articleUri => {
      if (articleUri) {
        httpUris.push('https://en.wikipedia.org'.concat(articleUri))
        pathArticles.push(articleUri.split('/wiki/')[1])
      }
    })
    this.setState({pathArticles: pathArticles, httpUris: httpUris})
  }

  render() {
    if (!this.state.pathArticles) {
      return null
    }
    return(
      <div>
        {this.state.pathArticles.map((article, index) => {
          if (index === 0){
            return <p key={index}><strong><a href={this.state.httpUris[index]} target="_blank">{article}</a> ↴</strong></p>
          }
          if (index === this.state.pathArticles.length-1) {
            return <p key={index}><strong><a href={this.state.httpUris[index]} target="_blank">{article}</a></strong></p>
          }
          return <p key={index}><a href={this.state.httpUris[index]} target="_blank">{article}</a> ↴</p>
        })}
      </div>
    )
  }
}

class WikipediaPathWithImages extends React.Component {
  state = {
    titlesToImageSources: {},
    imageSourcesFetched: false,
    titles: undefined,
  }

  componentDidMount() {
    const wikipediaMediaApiURI = "https://en.wikipedia.org/w/api.php?&origin=*&action=query&prop=pageimages&titles="
    const wikipediaMediaAPIThumbnailSize = "&pithumbsize=500&format=json"

    let articleTitles = []
    let articleUris = this.props.path.split('|')
    articleUris.pop() // remove last element from array (empty string)

    articleUris.forEach(articleUri => {
      articleTitles.push(articleUri.split('/wiki/')[1])
    })

    this.setState({titles: articleTitles})

    let titlesToImageSources = {}
    articleTitles.forEach(title => {
      const requestUri = `${wikipediaMediaApiURI}${title}${wikipediaMediaAPIThumbnailSize}`
      const requestOptions = {
        method: 'GET',
      }      
      fetch(requestUri, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let noImageSource = true
          if ('query' in data) {
            if (data['query'] && 'pages' in data['query']){
              let keys = Object.keys(data['query']['pages'])
              let firstKey = undefined
              if (keys.length > 0){
                firstKey = keys[0]
              }
              if (firstKey && 'thumbnail' in data['query']['pages'][firstKey]) {
                if ('thumbnail' in data['query']['pages'][firstKey]) {
                  let thumbnailObject = data['query']['pages'][firstKey]['thumbnail']
                  if (thumbnailObject) {
                    if ('source' in thumbnailObject) {
                      titlesToImageSources[title] = thumbnailObject['source']
                      noImageSource = false
                    }
                  }
                }
              }
            }
          }
          if (noImageSource) {
            titlesToImageSources[title] = undefined
          }
          if (Object.keys(titlesToImageSources).length === articleUris.length) {
            this.setState({imageSourcesFetched: true, titlesToImageSources: titlesToImageSources})
          }
        })
    })
  }

  render() {
    if (!this.state.imageSourcesFetched || this.state.titles === undefined) {
      return null
    }
    else {
      let imageDivs = []
      this.state.titles.forEach((title, index) => {
        if (title in this.state.titlesToImageSources && this.state.titlesToImageSources[title] !== undefined) {
          imageDivs.push(<div><img src={this.state.titlesToImageSources[title]} alt={title} style={{maxWidth: '200px', maxHeight: '200px', marginRight: '15px'}}/>→</div>)      
        }
        else {
          imageDivs.push(<div><img src="" alt={title}/>→</div>)
        }
      })
      return (
        <div style={{display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap'}}>
         {imageDivs}
        </div>
      )
    }
  }
}

class WikipediaGameForm extends React.Component {
  state = {
    startArticleUri: "",
    endArticleUri: "",
    startArticleImageSource: "",
    endArticleImageSource: "",
    isProcessing: false,
    isFinished: false,
    path: '',
    numNodesExplored: undefined,
    somethingWentWrong: false,
    errorMsg: undefined
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  getSearchPath(databaseId) {
    if (!this.state.isProcessing) {
      return
    }
    let searchQueryUriWithParams = SEARCH_QUERY_URI + '?id=' + databaseId
    const requestOptions = {
      method: 'GET',
    }
    fetchWithTimeout(searchQueryUriWithParams, requestOptions, 5000)
      .then((response) => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(text)
          })
        }
        return response.json()
      })
      .then((data) => {
        if (data.finished) {
          this.setState({isProcessing: false, isFinished: true, path: data.path, numNodesExplored: data.num_nodes_explored})
          return
        }
        setTimeout(() => this.getSearchPath(databaseId), 2000)})
      .catch(err => {
        console.log(err)
        this.setState({somethingWentWrong: true, errorMsg: err.toString()})
      })
  }
  
  submitToServer() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-type' : 'application/json',
      },
      body: JSON.stringify({
        start_article: this.state.startArticleUri,
        end_article: this.state.endArticleUri,
      }) 
    }
    fetchWithTimeout(SEARCH_QUERY_URI + '/', requestOptions, 5000)
      .then((response) => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(text)
          })
        }
        return response.json()
      })
      .then((data) => {
        this.getSearchPath(data.id)
      })
      .catch(err => {
        console.log(err)
        this.setState({somethingWentWrong: true, errorMsg: err.toString()})
      })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      isProcessing: true,
      path: '',
      isFinished: false,
      numNodesExplored: undefined,
      somethingWentWrong: false,
      errorMsg: undefined
    })
    this.submitToServer()
  }

  render() {
    return(
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={{ marginBottom: `10px`}}>
            <label>
              Start Article URI
              <input
                type="text"
                name="startArticleUri"
                value={this.state.startArticleUri}
                onChange={this.handleInputChange}
                style={{ marginLeft: '5px', width: '300px' }}
              />
            </label>
          </div>
          <div style={{ marginBottom: `10px`}}>
            <label>
              End Article URI
              <input
                type="text"
                name="endArticleUri"
                value={this.state.endArticleUri}
                onChange={this.handleInputChange}
                style={{ marginLeft: '5px', width: '300px'}}
              />
            </label>
          </div>
          <button type="submit" style={{ cursor: 'pointer' }}>Find Path!</button>
        </form>   
        {this.state.somethingWentWrong &&
          <div>
            <div>Something went wrong. Try again later.</div>
            <div>{this.state.errorMsg}</div>
          </div>
        }
        {!this.state.somethingWentWrong && this.state.isProcessing &&
          <div>
            <p>Searching for a path from {this.state.startArticleUri} to {this.state.endArticleUri}</p> 
          </div>
        }
        {!this.state.somethingWentWrong && this.state.isFinished && this.state.path &&
          <div>
            <p>Found a path from {this.state.startArticleUri} to {this.state.endArticleUri}</p>
            <WikipediaSolutionPath path={this.state.path}/>
            <div style={{width: '100%', height: '2px', border: '2px solid black', marginBottom: '15px'}}/>
            <WikipediaPathWithImages path={this.state.path}/>
            <div style={{width: '100%', height: '2px', border: '2px solid black', marginBottom: '15px'}}/>
            <div style={{marginTop: `50px`}}><p>Explored <strong>{this.state.numNodesExplored}</strong> articles</p></div>
          </div>
        }
        {!this.state.somethingWentWrong && this.state.isFinished && !this.state.path &&
          <div style={{marginTop: `50px`}}>
            <p>Could not find a path from {this.state.startArticleUri} to {this.state.endArticleUri} after exploring {this.state.numNodesExplored} articles</p>
          </div>
        }
      </div>
    )
  }
}

function Header() {
  const HeaderContainer = styled.div`
    width: 100%
  `

  const notify = () => toast("Link copied to clipboard");

  return (
    <HeaderContainer>
      <div>
        <p><b>The Wikipedia Game</b> tries to find a path between any two wikipedia articles. It uses a modified breadth-first search and expands the first 10 unique child articles on each article it explores. This algorithm will explore at most 1000 articles before calling it quits :).</p>
        <p><b>Coming soon:</b> iterative-deepening depth-first search, improving memory-efficiency, and customizing how many child nodes to explore for each article.</p>
        <p><b><a href="/introducing-the-wikipedia-game/" target="_blank">Read the blog post!</a></b></p>
        <p>
          This service only works with Wikipedia articles in English Wikipedia. URL format should be https://en.wikipedia.org/wiki/...
          <br/>
          <br/>
          <b>Examples</b><br />
          Start Article: <b><a style={{cursor:"pointer"}} onClick={() => {
                              navigator.clipboard.writeText("https://en.wikipedia.org/wiki/Bo%C3%B6tes_void");notify();
                            }}>https://en.wikipedia.org/wiki/Bo%C3%B6tes_void</a></b> Bootes Void
          <br />
          End Article: <b><a style={{cursor:"pointer"}} onClick={() => {
                              navigator.clipboard.writeText("https://en.wikipedia.org/wiki/Mariana_Trench");notify();
                            }}>https://en.wikipedia.org/wiki/Mariana_Trench</a></b> Mariana Trench
        </p>
      </div>
    </HeaderContainer>
  )
}
export default function WikipediaGame() {
  return (
    <Layout>
      <div style={{ margin: `3rem auto`, maxWidth: 800, padding: `0 1rem` }}>
        <Header/>
        <WikipediaGameForm/>
      </div>
    </Layout>
  )
}